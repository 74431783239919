import React from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import TopBar from "./TopBar";

const Resume = () => {
  return (
    <div className="terminal-outer">
      <div className="terminal-inner">
        <TopBar />

        <NavBar />

        <div className="banner-link-outer">
          <a
            href="Veronika Pilipenko Resume.pdf"
            target="_blank"
            className="banner-link"
            id="link"
          >
            🧡 Click here to download my resume 🧡
          </a>
        </div>

        <div className="work-experience">
          <div className="heading-top">Work Experience:</div>
          <div className="heading-top">Phamily - Jaan Health</div>
          <div className="info" id="heading1">
            ~Fullstack Software Engineer
          </div>
          <p className="info-inner">
            🐛 Developed dynamic and interactive web application features using
            Vue.js, Pug, and JavaScript <br />
            Improved the performance and usability of existing applications by
            identifying and fixing bugs, optimizing database queries, and
            integrating new features
            <br />
            🐛 Worked with databases like PostgreSQL and MySQL, and implemented
            caching techniques to improve the performance of the applications
            <br />
            🐛 Worked daily in a PHI-sensitive environment utilizing best
            practices in HIPAA compliance
            <br />
            🐛 Collaborated with cross-functional teams, including product
            managers, designers, and QA engineers, to ensure timely delivery of
            high-quality software products
            <br />
          </p>

          <div className="heading-top">Structy.net</div>
          <div className="info" id="heading1">
            ~Frontend Software Engineer
          </div>
          <p className="info-inner">
            🚀 Implemented reusable React components and libraries, resulting in
            increased development efficiency <br />
            🚀 Created pixel-perfect UI designs and worked closely with UX
            designers to ensure the best user experience
            <br />
            🚀 Collaborated with backend developers to integrate APIs and ensure
            seamless communication between the frontend and backend systems
            <br />
          </p>

          <div className="heading-top">Vault Health</div>
          <div className="info" id="heading1">
            ~Training-Lead/Customer-Experience-Associate
          </div>
          <p className="info-inner">
            🐸Coordinated and implemented an onboarding program for new
            employees; trained 3+ new hires each week <br />
            🐸Managed and organized ongoing technical training, visual aids, and
            educational material for team-members
            <br />
            🐸Effectively managed and solved 150+ customer service inquires
            daily
            <br />
            🐸Proficiently worked with technologies such as Slack, Zoom,
            Kustomer, EMR, Tableau, TalkDesk, and Salesforce
            <br />
          </p>

          <div className="heading-top">Go H20 Swimming</div>
          <div className="info" id="heading2">
            ~Founder/Main-Swim-Instructor
          </div>
          <p className="info-inner">
            🐬Built and managed a clientele of over 50 people; assisting each in
            their own personal aquatic goals
            <br />
            🐬Developed and managed all marketing and operational efforts
            including online ads, email blasts, social media
            <br />
            and direct client outreach
            <br />
            🐬Directed all organizational and client operations in order to
            maximize productivity and company growth
            <br />
            🐬Taught swimming to children and adults ranging from months of age
            to elderly, including competition level
            <br />
          </p>
        </div>
        <div className="skills">
          <div className="heading-top">Skills:</div>
          <p className="info-inner">
            JavaScript (ES6^), React (16.8^), Redux,  VueJS (2 & 3), Quasar,
            PugJS, Ruby on Rails, Mongoose, Node.js, Express, Tailwind, Git, API
            Integration, jQuery, HTML/CSS, SQL (MySQL/Postgres), noSQL
            (Mongo),AWS (s3 / containers), CI/CD (Github Actions), Monitoring
            Software (Datadog), Caching (Redis), Heroku, Vite, Webpack, NPM /
            Yarn, Automated Software Testing (Cypress), Test Driven
            Development(Unit/Functional via Cypress)
          </p>
        </div>

        <div className="languages">
          <div className="heading-top">Languages:</div>
          <p className="info-inner">English, Russian, Spanish(learning)</p>
        </div>
      </div>
    </div>
  );
};

export default Resume;
